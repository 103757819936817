<template>
  <v-app>
    <dashboard-core-app-bar />

    <dashboard-core-view />

    <!-- <dashboard-core-settings /> -->
  </v-app>
</template>

<script>
/* eslint-disable */
import router from "../../router";
export default {
  name: "DashboardIndex",

  components: {
    DashboardCoreAppBar: () => import("./components/core/AppBar-new"),

    // DashboardCoreSettings: () => import("./components/core/Settings"),
    DashboardCoreView: () => import("./components/core/View"),
  },

  data: () => ({
    expandOnHover: false,
  }),
  mounted() {
    const Islogged = localStorage.getItem("signedin");
    if (Islogged) {
      console.log("login success");
    } else {
      console.log("Bad Login");
      router.push("/");
    }
  },
};
</script>
